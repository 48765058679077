import React from "react";
import biopic from "../../assets/images/biopic.jpg";

class About extends React.Component {
  render() {
    const languages = [
      { name: "JavaScript", versions: ["ES5", "ES6"] },
      { name: "HTML", versions: ["4", "5"] },
      { name: "CSS", versions: ["2", "3", "SASS"] },
      { name: "JSX", versions: [] }
    ];
    const frameworks = ["React", "Jquery", "Node"];
    const tools = ["Webpack", "Grunt", "Gulp"];
    const applications = [
      "Adobe Creative Suite",
      "Microsoft Office",
      "Audacity",
      "Sony Acid",
      "Various code editors"
    ];
    return (
      <section id="about">
        <article className="details outerElement">
          <h1>Behind the Labs</h1>
          <i>Pay no attention to that man behind the curtain!</i>
        </article>
        <article id="aboutmeBox" className="outerElement">
          <h2>About Me</h2>
          <img id="bioPic" src={biopic} alt="Bio Pic" />
          <p>Hello there.</p>
          <p>
            I'm a <b>Chicago</b> based developer with experience in web
            development, multimedia creation, and editing software.
          </p>
          <p>
            My <b>Interactive Arts and Media</b> major exposed me to all sorts
            of digital media. I learned to design my own images, sounds,
            animations, and how to create applications to encapsulate them all.
          </p>
          <p>
            I've been working professionally with{" "}
            <b>HTML, CSS, and Javascript</b> since 2012. Eventually my career
            became more engineering focused and I was introduced to other
            languages, and concepts which I use when creating my applications.
          </p>
          <p>
            The goal now is to become a confident full stack developer with a
            specialization in front end technology. I also have an interest for{" "}
            <b>User Experience Design</b> which I take every opportunity to
            learn about and use in my work.
          </p>
        </article>
        <article id="ProficiencyBox" className="outerElement">
          <h2>Technical Proficiency</h2>
          <h3>Languages</h3>
          <ul>
            {languages.map(language => {
              return (
                <li className="devTags" key={language.name}>
                  {language.name}
                </li>
              );
            })}
          </ul>
          <h3>Frameworks</h3>
          <ul>
            {frameworks.map(frameworks => {
              return (
                <li className="devTags" key={frameworks}>
                  {frameworks}
                </li>
              );
            })}
          </ul>
          <h3>Tools</h3>
          <ul>
            {tools.map(tool => {
              return (
                <li className="devTags" key={tool}>
                  {tool}
                </li>
              );
            })}
          </ul>
          <h3>Applications</h3>
          <ul>
            {applications.map(applications => {
              return (
                <li className="devTags" key={applications}>
                  {applications}
                </li>
              );
            })}
          </ul>
        </article>
        <article id="skillsBox" className="outerElement">
          <h2>Skills</h2>
          <ul>
            <li>Utilizing API service-based architectures (REST, GraphQL)</li>
            <li>
              Setting up development environments (Including using Webpack, and
              task runners like Grunt/Gulp)
            </li>
            <li>Writing unit tests (Using Mocha, Chai, and Enzyme)</li>
            <li>
              Working with Atlassian services (such as JIRA, Trello, Confluence)
            </li>
            <li>
              Working in Agile software development environments (Scrum, Kanban)
            </li>
            <li>Using version control (SVN, GIT)</li>
            <li>Debugging and troubleshooting</li>
            <li>Writing user and technical documentation</li>
          </ul>
        </article>
        <article id="experienceBox" className="outerElement">
          <h2>Work Experience</h2>
          <h4>The Jellyvision Lab, Inc.</h4>
          <p>
            <i>
              Known for their benefits communication software Alex (used by over
              1,500 companies, including 114 of the Fortune 500) that helps
              employees make better decisions about their benefits and finances.
            </i>
          </p>

          <h3>SENIOR SOFTWARE ENGINEER</h3>
          <h4>JAN 2017 – JAN 2020</h4>
          <ul>
            <li>
              Helped with the onboarding of our engineering team onto JV's
              proprietary tech in order to produce our Alex on Leave of Absence
              product in a timely manner.
            </li>
            <li>
              Created an internal testing tool that helped speed up development
              work and allowed the engineering team to narrow down and fix data
              related bugs.
            </li>
            <li>
              Extended an existing internal debugging tool to automate JIRA
              ticket creation.
            </li>
            <li>
              Helped in the migration of the Leave of Absence v1 product to use
              JV’s modern platform and services.
            </li>
            <li>
              Architected tooling and processes that would allow our production
              team to build a component of our Leave of Absence v2 product using
              React and JSX.
            </li>
          </ul>
          <h3>FRONT END WEB DEVELOPER</h3>
          <h4>MAY 2014 – JAN 2017</h4>
          <ul>
            <li>
              Updated one of JV's internal frameworks to be mobile friendly and
              responsive. This framework ended up being used in 5 client
              projects and 2 internal ones.
            </li>
            <li>
              Delivered on time to a client a configurable AngularJS based
              product that required working closely with a client's engineering
              team, and their third party consultant.
            </li>
          </ul>
          <h3>JUNIOR FRONT END WEB DEVELOPER</h3>
          <h4>NOV 2012 – MAY 2014</h4>
          <ul>
            <li>
              Worked closely with Project Managers and the Production team
              (Writers, Artists, Media and Sound Producers) to deliver on time a
              variety of HTML, JS, PHP, and Flash based client projects.
            </li>
            <li>
              Created an internal debugging tool used to collect a snapshot of
              the data, device specifics, and other useful information used for
              fixing bugs.
            </li>
          </ul>
          <h3>FRONT END WEB DEVELOPER CONTRACTOR</h3>
          <h4>AUG 2012 – NOV 2012</h4>
          <ul>
            <li>
              Led the production of multiple (10) client projects that used JV's
              internal framework, including some that were translated to
              multiple languages.
            </li>
          </ul>
          <h3>FRONT END WEB DEVELOPER INTERN</h3>
          <h4>JUN 2012 – AUG 2012</h4>
          <ul>
            <li>
              Produced HTML content, maintained CSS styling, and programed
              customized functionality for one of JV's internal HTML/JS based
              frameworks.
            </li>
          </ul>
        </article>
      </section>
    );
  }
}

export default About;
