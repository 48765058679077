import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Timeline } from 'react-twitter-widgets';

import Carousel from 'react-images';
import { format } from 'date-fns';

import SanitizeHTML from '../SanitizedHTML';

class Home extends React.Component {
  render() {
    const { projects, posts } = this.props;
    const hideBlog = true;
    return (
      <section id='home'>
        <article className='details outerElement'>
          <h1>Welcome to the Labs!</h1>
          <p>I use web technologies to bring ideas to life.</p>
        </article>
        <figure id='recentProjects' className='outerElement'>
          <h2>Check out the latest creations:</h2>
          {projects.map((project, index) => {
            if (project.featured) {
              return (
                <a
                  key={index}
                  href={project.caseUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Carousel views={[{ source: project.bannerImage }]} />
                </a>
              );
            }
          })}
        </figure>
        {!hideBlog && (
          <article id='postBox' className='outerElement'>
            {posts.map((post, index) => {
              const { tags, title, date, image, body } = post;
              const allTags = tags.split(',').map((tags) => tags.trim());
              return (
                <div className='blogPost' key={`${title}-${index}`}>
                  <h2>{title}</h2>
                  <i className='blogDate'>
                    {format(new Date(date), 'MMMM Do, yyyy')}
                  </i>
                  <img src={image[0].url} alt='Blog' />
                  <SanitizeHTML html={body} />
                  <div>
                    {allTags.map((tag, i) => {
                      return (
                        <button key={`${tag}-${i}`} type='button'>
                          {`#${tag}`}
                        </button>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </article>
        )}
        <article id='twitterBox' className='outerElement'>
          <Timeline
            dataSource={{
              sourceType: 'profile',
              screenName: 'reanimationLabs'
            }}
            options={{
              username: 'reanimationLabs',
              height: '400'
            }}
          />
        </article>
      </section>
    );
  }
}

export default Home;
