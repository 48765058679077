import axios from 'axios';
import CONFIG from '../config/config.json';

function getCMSData() {
  const requests = [
    axios.get(`${CONFIG.contentAPIUrl}getProjects.json`),
    axios.get(`${CONFIG.contentAPIUrl}getPosts.json`)
  ];
  return new Promise((resolve, reject) => {
    axios
      .all(requests)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}

export { getCMSData };
export default getCMSData;
