function formContent() {
  const content = [
    {
      question: "I would like...",
      options: [
        { text: "To connect", value: "connect" },
        { text: "Something else", value: "other" }
      ],
      name: "reason",
      conditions: [],
      responses: [
        {
          value: "connect",
          text:
            "Send me a message through <a href='https://www.linkedin.com/in/earicardo/' target='_blank' rel='noopener noreferrer'>LinkedIn</a> and let's connect."
        },
        {
          value: "other",
          text:
            "Send me an email at <a href='mailto:reanimationlabs@outlook.com'>reanimationlabs@outlook.com</a> and I'll follow up ASAP."
        }
      ]
    }
  ];

  return content;
}

export default formContent;
