import React from "react";
import loader from "../assets/images/loader.svg";

function Loader() {
  return (
    <div id="loader">
      <img className="spinner" src={loader} alt="loading" />
    </div>
  );
}

export default Loader;
