import React from "react";
import formContent from "./formContent";
import SanitizeHTML from "../SanitizedHTML";
import resetIcon from "../../assets/images/reset-icon.svg";

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    const content = formContent();
    const responses = {};
    content.forEach((item, i) => {
      responses[item.name] = null;
    });
    this.state = {
      content,
      responses
    };
  }

  response(name, value) {
    this.setState((prevState, props) => ({
      responses: {
        ...prevState.responses,
        [name]: value
      }
    }));
  }

  conditionsAreMet(conditions) {
    const { responses } = this.state;
    if (conditions.length > 0) {
      return conditions.every(condition => {
        return responses[condition.name] === condition.value;
      });
    }
    return true;
  }

  isFormCompleted() {
    const { responses } = this.state;
    return Object.keys(responses).every(response => {
      return responses[response] !== null;
    });
  }

  restartForm() {
    const { responses } = this.state;
    const updatedResponses = {};
    Object.keys(responses).forEach(response => {
      updatedResponses[response] = null;
    });
    this.setState({
      responses: updatedResponses
    });
  }

  render() {
    console.log(this.state);
    const { content, responses } = this.state;
    return (
      <div id="dynamicForm">
        {content.map(item => {
          return (
            <div key={`${item.name}-question`}>
              {responses[item.name] === null &&
                this.conditionsAreMet(item.conditions) && (
                  <div name={item.name}>
                    <h3>{item.question}</h3>
                    <ul>
                      {item.options.map(option => {
                        return (
                          <li key={`${option.value}-option`}>
                            <button
                              type="button"
                              onClick={() =>
                                this.response(item.name, option.value)
                              }
                            >
                              {option.text}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              {item.responses.map(response => {
                if (responses[item.name] === response.value) {
                  return (
                    <div key={`${response.value}-response`}>
                      <SanitizeHTML html={response.text} />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        })}
        {this.isFormCompleted() && (
          <div>
            <button
              type="button"
              id="resetButton"
              onClick={() => {
                this.restartForm();
              }}
            >
              <img src={resetIcon} alt="reset button" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default DynamicForm;
