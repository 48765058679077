import React from "react";
import reanimationbanner from "../../assets/images/reanimationlabsbannerW.svg";
import reanimationbannerAlt from "../../assets/images/reanimationlabsbannerWAlt.svg";

function Header(props) {
  const { handleNavChange } = props;
  return (
    <header tabIndex="0">
      <img
        id="logo"
        src={reanimationbanner}
        alt="ricardoworks banner"
        onClick={() => handleNavChange({ value: "Home" })}
      />
      <img
        id="logoAlt"
        src={reanimationbannerAlt}
        alt="ricardoworks banner"
        onClick={() => handleNavChange({ value: "Home" })}
      />
    </header>
  );
}

export default Header;
