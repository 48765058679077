import React from "react";
import DynamicForm from "../form/DynamicForm";

class Contact extends React.Component {
  render() {
    return (
      <section>
        <article id="contactBox" className="outerElement">
          <DynamicForm />
        </article>
      </section>
    );
  }
}

export default Contact;
