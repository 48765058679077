import React from "react";
import Slide from "react-reveal/Slide";

import linkedinLogo from "../../assets/images/linkedin-icon.svg";
import twitterLogo from "../../assets/images/twitter-icon.svg";
import emailLogo from "../../assets/images/email-icon.svg";

function Footer() {
  const address = "mailto:reanimationlabs@outlook.com";
  return (
    <Slide bottom cascade>
      <footer>
        <ul id="socialMedia">
          <li>
            <a
              href="https://www.linkedin.com/in/earicardo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinLogo} alt="LinkedIn Logo" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/reanimationLabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterLogo} alt="Twitter Logo" />
            </a>
          </li>
          <li>
            <a href={address} target="_blank" rel="noopener noreferrer">
              <img src={emailLogo} alt="Email Logo" />
            </a>
          </li>
        </ul>
        <p>
          <small>
            <i>
              Changes are periodically made to the information contained in this
              web site, which may be incorporated in new editions of the
              reanimationlabs.com website without prior notice.
              <br />
              All trademarks,logos and copyrights are the property of their
              respective owners.
              <br />
              Web Design and Development by reanimationlabs.com.
            </i>
          </small>
        </p>
      </footer>
    </Slide>
  );
}

export default Footer;
