import React from "react";
import sanitizeHtml from "sanitize-html";

const defaultOptions = {
  allowedTags: ["b", "i", "em", "strong", "a", "p", "div"],
  allowedAttributes: {
    a: ["href", "target"]
  }
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, (options: { ...defaultOptions, ...options }))
});

const SanitizeHTML = ({ html, options }) => (
  <div dangerouslySetInnerHTML={sanitize(html, options)} />
);

export default SanitizeHTML;
export { SanitizeHTML };
