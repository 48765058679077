import React from 'react';
import 'normalize.css';
import './styles/App.scss';

import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Nav from './components/layouts/Nav';
import Home from './components/views/Home';
import Projects from './components/views/Projects';
import About from './components/views/About';
import Contact from './components/views/Contact';
import ErrorModal from './components/ErrorModal';
import Loader from './components/Loader';
import getCMS from './services/getCMSData';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      loading: true,
      view: 'Home',
      posts: [],
      projects: []
    };
    this.handleNavChange = this.handleNavChange.bind(this);
    this.updateErrorModule = this.updateErrorModule.bind(this);
    this.updateLoader = this.updateLoader.bind(this);
  }

  views = {
    Home,
    Projects,
    About,
    Contact
  };

  componentDidMount() {
    getCMS()
      .then((response) => {
        const projectRequest = response[0]?.data;
        const postRequest = response[1]?.data;
        this.setState({
          projects: projectRequest.projects,
          posts: postRequest.posts,
          loading: false
        });
      })
      .catch((error) => {
        const { errors } = this.state;
        const allErrors = errors.concat(error);
        this.setState({ errors: allErrors });
      });
  }

  updateErrorModule(errors) {
    this.setState({ errors });
  }

  updateLoader(loading) {
    this.setState({ loading });
  }

  handleNavChange = (selectedOption) => {
    this.setState({ view: selectedOption.value });
  };

  render() {
    const { loading, errors, view, posts, projects } = this.state;
    const View = this.views[view];
    if (loading) {
      return <Loader />;
    }
    return (
      <>
        <ErrorModal
          errors={errors}
          updateErrorModule={this.updateErrorModule}
        />
        <div id='fixedHead'>
          <Header handleNavChange={this.handleNavChange} />
          <Nav handleNavChange={this.handleNavChange} />
        </div>
        <main id='views'>
          <View
            posts={posts}
            projects={projects}
            updateLoader={this.updateLoader}
          />
        </main>
        <Footer />
      </>
    );
  }
}

export default App;
