import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import SanitizeHTML from '../SanitizedHTML';

class Projects extends React.Component {
  constructor(props) {
    super(props);
    const { projects } = this.props;
    const lightbox = {};
    projects.forEach((item, i) => {
      lightbox[item.displayName] = false;
    });
    this.state = {
      lightbox
    };
  }

  render() {
    const { projects } = this.props;
    const { lightbox } = this.state;
    return (
      <section id='workshop'>
        <article className='details outerElement'>
          <h1>It's Alive!</h1>
          <p>
            Check out the creations.
            <br /> If you're interested in the work feel free to contact me.
          </p>
        </article>
        <article id='AllWorks' className='outerElement'>
          <div id='pieces'>
            {projects.map((item, index) => {
              const {
                description,
                displayName,
                logo,
                preview,
                siteUrl,
                name
              } = item;
              const Footer = ({ innerProps, isModal }) =>
                isModal ? (
                  <div className='workDescriptionText'>
                    <h3>{displayName}</h3>
                    <SanitizeHTML html={description} />
                    <p className='workLinkText'>
                      You can view it&nbsp;
                      <a
                        href={siteUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        here
                      </a>
                      .
                    </p>
                  </div>
                ) : null;
              return (
                <div key={name} className='piece'>
                  <button
                    className='pieceCover'
                    onKeyPress={(target) => {
                      if (target.charCode === 13) {
                        this.setState({
                          lightbox: { ...lightbox, [name]: true }
                        });
                      }
                    }}
                    onClick={() => {
                      this.setState({
                        lightbox: { ...lightbox, [name]: true }
                      });
                    }}
                  >
                    <img src={logo} alt='Logo' />
                  </button>
                  <ModalGateway>
                    {lightbox[name] ? (
                      <Modal
                        onClose={() => {
                          this.setState({
                            lightbox: { ...lightbox, [name]: false }
                          });
                        }}
                      >
                        <Carousel
                          views={[{ source: preview }]}
                          components={{ Footer }}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>
              );
            })}
          </div>
        </article>
      </section>
    );
  }
}

export default Projects;
