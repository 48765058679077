import React from "react";
import Select from "react-select";

class Nav extends React.Component {
  static options = ["Home", "Projects", "About", "Contact"];

  render() {
    const { handleNavChange } = this.props;

    return (
      <nav>
        <Select
          options={Nav.options.map(option => {
            return { label: option, value: option };
          })}
          onChange={handleNavChange}
          classNamePrefix="nav"
          className="nav"
          placeholder="Home"
          id="navMenu"
        />
        <ul id="navBar">
          {Nav.options.map(item => {
            return (
              <li key={item}>
                <button
                  type="button"
                  onClick={() => handleNavChange({ value: item })}
                  onKeyPress={target => {
                    if (target.charCode === 13) {
                      handleNavChange({ value: item });
                    }
                  }}
                >
                  {item}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Nav;
