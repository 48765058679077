import React from "react";
import PropTypes from "prop-types";

class ErrorModal extends React.Component {
  handleClose(error) {
    const { errors, updateErrorModule } = this.props;
    const updatedErrors = errors.filter(er => {
      return error !== er;
    });
    updateErrorModule(updatedErrors);
  }

  render() {
    const { errors } = this.props;

    if (errors.length) {
      return (
        <div id="errorModal">
          {errors.map(error => {
            return (
              <p key={error}>
                {error}
                <button
                  className="closeError"
                  onClick={() => this.handleClose(error)}
                  onKeyDown={() => this.handleClose(error)}
                  type="button"
                >
                  &#10006;
                </button>
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  }
}

ErrorModal.propTypes = {
  errors: PropTypes.array.isRequired,
  updateErrorModule: PropTypes.func.isRequired
};

export default ErrorModal;
